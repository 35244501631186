<template>
  <div class="wrap">
    <el-empty v-if="list.length == 0" description="空空如也~"></el-empty>
    <ul v-else class="order-ul" v-infinite-scroll="getData">
      <li class="list-box" v-for="(m, index) in list">
        <router-link :to="{ path: '/actdes', query: { id: m.id } }">
          <div class="list">
            <div class="content">
              <h3 class="title">{{ m.content }}</h3>
            </div>
            <p class="price">
              活动状态：
              <span>{{ !m.state ? '进行中' : '已结束' }}</span>
            </p>
            <p class="price">
              活动时间：
              <span>{{ m.activity_time }}</span>
            </p>
            <p class="price">
              活动付费：
              <span>{{ m.pay == 0 ? '发起方' : '报名者' }}买单</span>
            </p>
            <div class="area flex" v-if="m.address_name">
              <i class="iconfont icon-zhiyuandidian6"></i>
              {{ m.address_name }}
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },
  created() {
    // 在组件实例处理完所有与状态相关的选项后调用。
    this.getData(1);
  },
  mounted() {
    // 在组件被挂载之后调用。
  },
  methods: {
    // ...mapMutations([])
    async getData() {
      this.list = await this.$y_list("api/activity/myactivity", this.list);
    },
  },
  computed: {
    // 计算属性
    // ...mapGetters([])
  },
  watch: {
    // 监听
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/less/order.less");
</style>
